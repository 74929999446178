import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-durham/src/components/layout';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import { Link } from 'gatsby';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import { animateScroll as scroll } from 'react-scroll';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

import gatsbyConfig from '@accrosoft-ltd/accropress-theme-durham/theme-config';
import BasicSlider from '@accrosoft-ltd/accropress-theme-durham/src/components/BasicSlider';

import ExtraHomepageContentTop from './HomepageComponents/extraHomepageContentTop';
import ExtraHomepageContentBottom from './HomepageComponents/extraHomepageContentBottom';
import HeroInnerContent from '@accrosoft-ltd/accropress-theme-durham/src/templates/HomepageComponents/heroInnerContent';

import buildMediaContent from '@accrosoft-ltd/accropress-websites-components/dist/esm/buildMediaContent';

import loadable from '@loadable/component';

const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader'),
);
const WeducNewsFeed = loadable(() =>
  import(
    '@accrosoft-ltd/accropress-websites-components/dist/esm/weducNewsfeed'
  ),
);
const WeducDairyDates = loadable(() =>
  import(
    '@accrosoft-ltd/accropress-websites-components/dist/esm/weducDairyDates'
  ),
);

var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;

require('es6-promise').polyfill();
var moment = require('moment');

export function NoticeTemplate(props) {
  return (
    <span className="noticesContent">
      {props.data.attachment &&
        props.data.attachment[0] &&
        props.data.attachment[0].external_url ? (
          <a
            href={props.data.attachment[0].external_url}
            title={props.data.attachment[0].title}
            target="_blank"
          >
            <strong className="title">
              {props.data.title.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
            </strong>
            <div dangerouslySetInnerHTML={{ __html: props.data.message }}></div>
          </a>
        ) : (
          <>
            <strong className="title">
              {props.data.title.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
            </strong>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: props.data.message }}
            ></div>
          </>
        )}
    </span>
  )
}

export default function HomepagePagePage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navResultData,
    pluginOptions,
  },
  props,
}) {
  const config = pluginOptions;

  const [toggled, setToggled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);
  const [noticesEmergencyLoaded, setNoticesEmergencyLoaded] = useState(false);
  const [newsfeedLoaded, setNewsfeedLoaded] = useState(false);
  const [calendarEventsLoaded, setCalendarEventsLoaded] = useState(false);

  const [noticesBannerExpanded, setNoticesBannerExpanded] = useState(false);

  const [videoPlayerModalTriggered, setVideoPlayerModalTriggered] = useState(false)

  useEffect(() => {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return (
          navigator.userAgent.match(/IEMobile/i) ||
          navigator.userAgent.match(/WPDesktop/i)
        );
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };

    var video = document.getElementById('hero_video_player');

    if (!isMobile.any() && video) {
      // get reference to video sources
      var sources = document.getElementsByClassName('video__source');

      // loop through and replace data-src with src
      for (var i = 0; i < sources.length; i++) {
        if (sources[i].getAttribute('data-src')) {
          sources[i].setAttribute('src', sources[i].getAttribute('data-src'));
          sources[i].removeAttribute('data-src'); // use only if you need to remove data-src attribute after setting src
        }
      }

      video.muted = true;

      // listen for canplay event and fade video in
      video.addEventListener('canplay', function () {
        //console.log('video duration information available');
        video.style.transition = 'opacity 2s';
        video.style.opacity = 1;
      });

      // reload video sources
      video.load();
      video.play();
    }
  }, [videoPlayerModalTriggered]);

  const checkContent = (content) => {
    let div = document.createElement('div');
    div.innerHTML = content;

    var image = div.getElementsByTagName('img')[0];

    if (image && !image.getAttribute('src').includes('external')) {
      image.remove();
    }

    return div.outerHTML;
  };

  const toggleNoticesBanner = function () {
    if (noticesBannerExpanded) {
      setNoticesBannerExpanded(false);
    } else {
      setNoticesBannerExpanded(true);
    }
  };

  console.log('integrators data: ');
  console.log(integratorData);

  console.log('content meta data: ');
  console.log(contentMetaData);

  console.log('content data data: ');
  console.log(contentDataData);

  const updateAndShowModal = (title, description, postType, attachment) => {
    setModalData({
      title: title,
      description: description,
      postType: postType,
      attachment: attachment,
    });
    setModalOpen(true);
  };

  const scrollToContent = (location, offset) => {
    scroller.scrollTo(location, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: offset || 50,
    });
  };

  return (
    <Layout>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />
      <Modal
        classNames={{ modal: "AccroModal" }}
        open={modalOpen && modalData ? true : false}
        onClose={() => setModalOpen(false)}
        center
      >
        {modalData && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: modalData.description }}
            ></div>
            {modalData.attachment && modalData.attachment.length > 0 && (
              <ul className="AccroModal_Attachments">
                {modalData.attachment.map((attachment, i) => {
                  return (
                    <li key={i} className="AccroModal_Attachments_Attachement">
                      <a href={attachment.external_url} title={attachment.title} target="_blank" rel="noopener noreferer">
                        {attachment.title}
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: buildMediaContent(modalData.postType),
              }}
            ></div>
          </>
        )}
      </Modal>

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          {pluginOptions.useHeroSlider ? (
            <BasicSlider
              OverlayContainer={
                <HeroInnerContent
                  contentDataData={contentDataData}
                  noticesMainLoaded={noticesMainLoaded}
                  setNoticesMainLoaded={setNoticesMainLoaded}
                  NoticeTemplate={NoticeTemplate}
                  noticesBannerExpanded={noticesBannerExpanded}
                  toggleNoticesBanner={toggleNoticesBanner}
                  config={config}
                  pluginOptions={pluginOptions}
                  scrollToContent={scrollToContent}
                  videoPlayerModalTriggered={videoPlayerModalTriggered}
                  setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
                />
              }
              onBeforeChangeFunction={(type, previous, next) => {
                return null;
              }}
              height={
                pluginOptions.heroSliderHeight
                  ? pluginOptions.heroSliderHeight
                  : 'calc(73vh - 50px)'
              }
              shouldDisplayButtons={false}
              onBeforeChangeFunction={(type, next) => {
                return null;
              }}
              onBeforeChangeFunction={(type, next) => {
                return null;
              }}
              slides={contentDataData[0].children[0].children}
            />
          ) : (
            <HeroInnerContent
              contentDataData={contentDataData}
              noticesMainLoaded={noticesMainLoaded}
              setNoticesMainLoaded={setNoticesMainLoaded}
              NoticeTemplate={NoticeTemplate}
              noticesBannerExpanded={noticesBannerExpanded}
              toggleNoticesBanner={toggleNoticesBanner}
              config={config}
              pluginOptions={pluginOptions}
              scrollToContent={scrollToContent}
              videoPlayerModalTriggered={videoPlayerModalTriggered}
              setVideoPlayerModalTriggered={setVideoPlayerModalTriggered}
            />
          )}
          <div id="scrollHere" className="scrollHere"></div>
          <div className="mainContentWrapper home" id="welcome">
            <section className="app-content">
              <div className="container">
                <div className="row homepageWrapper">
                  <ExtraHomepageContentTop contentDataData={contentDataData} />
                  <div className="col-12 col-12-m">
                    {contentDataData[1] &&
                      contentDataData[1].children &&
                      contentDataData[1].children[1] &&
                      contentDataData[1].children[1].value && (
                        <div className="row spacer">
                          <div className="col-12 col-3-m">
                            <div className="profile-image-container">
                              <img
                                src={returnResizedImageURL(
                                  {
                                    h: '863px',
                                  },
                                  contentDataData[1].children[0].value,
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-9-m">
                            <div className="profile-info-container">
                              <div className="light-blue">
                                {contentDataData[1] &&
                                  contentDataData[1].children &&
                                  contentDataData[1].children[1] && (
                                    <h2>{contentDataData[1].children[1].value}</h2>
                                  )}
                                <WigLoader
                                  weducWebsitesID={config.weducWebsitesID}
                                  weducContainer={config.weducContainerID}
                                  weducEntity={config.weducEntityID}
                                  contentDataData={contentDataData}
                                  contentMetaData={contentMetaData}
                                  contentDataArray="1"
                                  contentDataChild="2"
                                  AccroPressProdEndpoint={
                                    config.apiEndpoint
                                  }
                                  AccroPressLocalEndpoint={
                                    config.apiEndpoint
                                  }
                                  integratorID={config.integratorID}
                                  integratorAccessToken={
                                    config.integratorAccessToken
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}                   
                    {contentDataData[2] &&
                      contentDataData[2].children[0] &&
                      contentDataData[2].children[0].children &&
                      contentDataData[2].children[0].children.length > 0 && (
                        <div className="four-block-area">
                          <ul className="row blocks">
                            {contentDataData[2].children[0].children.map(
                              (column, i) => (
                                <li
                                  className={`col-12 col-${
                                    pluginOptions.infoGridMdWidth
                                      ? pluginOptions.infoGridMdWidth
                                      : '6'
                                  }-m block`}
                                  key={i}
                                >
                                  <Link
                                    to={column[2].value}
                                    title={column[1].value}
                                    className="readMore"
                                  >
                                    <div
                                      to={column[2].value}
                                      title={column[1].value}
                                      className="quickLinkTile"
                                      style={{
                                        backgroundImage: `url(${returnResizedImageURL(
                                          {
                                            w: '825px',
                                          },
                                          column[0].value,
                                        )})`,
                                      }}
                                    >
                                      <div className="wrap">
                                        <div className="block-logo"></div>
                                        <div className="quickLinkText">
                                          <span>{column[1].value}</span>
                                          <Link
                                            to={column[2].value}
                                            title={column[1].value}
                                            className="readMore"
                                          >
                                            Find out more
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      )}

                    <div className="row">
                      <div className="col-12 col-6-m">
                        <div
                          className={
                            'sidebarMenuItem weducWidgetDisplay_' +
                            newsfeedLoaded
                          }
                        >
                          <div className="header">
                            <h3>Latest News</h3>
                            {pluginOptions.newsPageRoute && (
                              <Link to={pluginOptions.newsPageRoute}>
                                View all
                              </Link>
                            )}
                          </div>
                          <WeducNewsFeed
                            loadedEvent={setNewsfeedLoaded}
                            limit={5}
                            name={config.weducWebsitesID}
                            container={config.weducContainerID}
                            html={function NewsFeedTemplate(props) {
                              return (
                                <div
                                  onClick={() =>
                                    updateAndShowModal(
                                      props.data.title,
                                      props.data.contentHtml,
                                      props.data.postType,
                                      props.data.attachment,
                                    )
                                  }
                                  className="sidebarDataItem"
                                >
                                  <span className="far fa-newspaper"></span>
                                  <div>
                                    <div className="content">
                                      <span>
                                        {moment(props.data.start_date).format(
                                          'LL',
                                        )}
                                      </span>
                                      {props.data.contentHtml
                                        .replace(/<\/p>]*>?/gm, ' ')
                                        .replace(/<[^>]*>?/gm, '')
                                        .substring(0, 120)}
                                      {props.data.contentHtml
                                        .replace(/<\/p>]*>?/gm, ' ')
                                        .replace(/<[^>]*>?/gm, '').length > 120
                                        ? '...'
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                            route={
                              pluginOptions.newsPageRoute
                                ? pluginOptions.newsPageRoute
                                : '/news-and-events/latest-news'
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 col-6-m">
                        <div
                          className={
                            'sidebarMenuItem weducWidgetDisplay_' +
                            calendarEventsLoaded
                          }
                        >
                          <div className="header">
                            <h3>Calendar</h3>
                            {pluginOptions.eventsPageRoute && (
                              <Link to={pluginOptions.eventsPageRoute}>
                                View all
                              </Link>
                            )}
                          </div>
                          <WeducDairyDates
                            weducAPIURL="https://weduc.accropress.com/smartfeed/"
                            limit={5}
                            loadedEvent={setCalendarEventsLoaded}
                            name={config.weducWebsitesID}
                            container={config.weducContainerID}
                            html={function CalendarTemplate(props) {
                              return (
                                <div
                                  onClick={() =>
                                    updateAndShowModal(
                                      props.data.title,
                                      props.data.description,
                                      props.data.postType,
                                      props.data.attachment,
                                    )
                                  }
                                  className="sidebarDataItem"
                                >
                                  <span className="far fa-calendar"></span>
                                  <div>
                                    {props.data.title}
                                    <span>
                                      {moment(props.data.start_date).format(
                                        'LL',
                                      )}
                                    </span>
                                  </div>
                                </div>
                              );
                            }}
                            route={
                              pluginOptions.eventsPageRoute
                                ? pluginOptions.eventsPageRoute
                                : '/news-and-events/events'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ExtraHomepageContentBottom
                    contentDataData={contentDataData}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
