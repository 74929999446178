import React from "react";

const ExtraHomepageContentTop = (props) => {
  console.log("ExtraHomepageContentTop", props);
  return (
    <>
      {props &&
        props.contentDataData &&
        props.contentDataData[3] &&
        props.contentDataData[3].children && (
          <div className="top-quicklinks-wrapper">
            <h2 className="top-quicklinks-title">
              {props.contentDataData[3].children[0].value}
            </h2>
            <div className="top-quicklinks-links">
              {props.contentDataData[3].children[1].children.map((link) => {
                return (
                  <a
                    href={link[0].value}
                    title={link[0].text}
                    className="top-quicklink-link btn btn-primary"
                  >
                    {link[0].text}
                  </a>
                );
              })}
            </div>
          </div>
        )}
    </>
  );
};
export default ExtraHomepageContentTop;
